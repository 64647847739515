<template>
  <b-modal
    v-model="isModalShow"
    title="รายการสินค้า"
    hide-footer
    @show="getProducts(false)"
    @hide="setShowModalProducts(false)"
  >
    <div class="wrap-product-panel">
      <b-input-group class="rounded-pill py-2">
        <b-form-input
          ref="keywordInput"
          v-model="filter.search"
          @keyup.enter="onSearchProduct()"
          placeholder="ค้นหาโดยชื่อสินค้า, รหัสบาร์โค้ด"
          style="border-radius:50px 0 0 50px"
        ></b-form-input>
        <template v-slot:append>
          <button
            class="btn bg-primary btn-search text-white rounded-pill-right"
            @click="getProducts(false)"
          >
            ค้นหา
          </button>
        </template>
      </b-input-group>
      <div class="product-panel">
        <div v-if="products.length >= 0" id="product-panel" class="mb-5">
          <div v-for="(item, index) in products" :key="index" class="my-2">
            <div class="p-3 rounded shadow-s bg-color-secondary h-110">
              <div class="d-flex">
                <div class="mr-2" v-if="item.imageUrl">
                  <img :src="item.imageUrl" class="product-image" />
                </div>
                <div
                  class="w-100  d-flex  flex-column"
                  :class="{ 'h-80': !item.imageUrl }"
                >
                  <div class="d-flex">
                    <div
                      :class="[
                        'line-clamp mr-1',
                        { 'active-card': item.availableQuantity > 0 }
                      ]"
                    >
                      {{ item.name }}
                    </div>
                    <div v-if="item.availableQuantity > 0" class="ml-auto">
                      <b-button
                        @click="onSelectedProduct(item)"
                        variant="danger"
                        size="sm"
                        class="rounded-pill btn-add-product"
                      >
                        เพิ่ม
                      </b-button>
                    </div>
                  </div>
                  <div class="line-clamp">
                    <div>{{ item.barcode }}</div>
                  </div>
                  <div class="d-flex" v-if="item.productDiscountType == 0">
                    <div
                      :class="[
                        'ml-auto ',
                        { 'price-active': item.availableQuantity > 0 }
                      ]"
                    >
                      <del v-if="item.promotionPrice !== 0">{{
                        item.price | numeral("0,0.00")
                      }}</del>
                    </div>
                  </div>
                  <div class="d-flex mt-auto">
                    <div
                      :class="[{ 'active-card': item.availableQuantity > 0 }]"
                    >
                      คงเหลือ {{ item.availableQuantity }} ชิ้น
                    </div>
                    <div
                      :class="[
                        'ml-auto ',
                        { 'price-active': item.availableQuantity > 0 }
                      ]"
                    >
                      <div v-if="item.promotionPrice !== 0">
                        <div>
                          ราคา {{ item.promotionPrice | numeral("0,0.00") }}
                        </div>
                        <div class="text-right">
                          <del class=" text-secondary">
                            {{ item.price | numeral("0,0.00") }}
                          </del>
                        </div>
                      </div>
                      <div v-else>
                        ราคา {{ item.price | numeral("0,0.00") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <infinite-loading
            spinner="spiral"
            @infinite="scrollMethod"
            ref="infiniteLoading"
          >
            <div slot="spinner" class="">
              <img
                v-if="isLoadingList && isLoadingMore"
                src="@/assets/images/loading.svg"
                alt="loading"
                class="loading-icon"
              />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </div>
        <div
          v-if="products.length < 1 && !isLoadingList"
          class="text-color-secondary text-center h-75 d-flex"
        >
          <span class="m-auto">
            ไม่พบสินค้า
          </span>
        </div>
        <div
          v-if="products.length < 1 && isLoadingList && !isLoadingMore"
          class="text-color-secondary text-center h-75 w-100 d-flex"
        >
          <div slot="spinner" class="h-100 w-100 ">
            <img
              src="@/assets/images/loading.svg"
              alt="loading"
              class="loading-icon my-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AddProduct",
  props: {
    isShow: {
      required: false,
      type: Boolean
    },
    brandSelected: [String, Number],
    branchSelected: [String, Number],
    addProductToLists: Function,
    productDiscounts: Array
  },
  data() {
    return {
      filter: {
        page: 1,
        search: "",
        take: 10,
        brandId: 0,
        branchId: 0
      },
      products: [],
      forceRefresh: true,
      busy: false,
      loadFlag: false,
      isLoadingList: false,
      isLoadingMore: false
    };
  },
  computed: {
    isModalShow: {
      get: function() {
        return this.isShow;
      },
      set: function() {}
    },
    branchId: {
      get: function() {
        return this.branchSelected;
      },
      set: function() {}
    },
    brandId: {
      get: function() {
        return this.brandSelected;
      },
      set: function() {}
    }
  },
  methods: {
    onSearchProduct() {
      this.products = [];
      this.getProducts(false);
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    },
    getProducts: async function(scrollFlag, $state) {
      if (!scrollFlag) this.filter.page = 1;
      this.filter.branchId = this.branchId;
      this.filter.brandId = this.brandId;
      this.isLoadingList = true;
      await this.$axios
        .get(this.$baseUrlOrder + "/api/product", { params: this.filter })
        .then(response => {
          this.isLoadingList = false;
          this.isLoadingMore = false;
          if (response.data.result) {
            if (scrollFlag) {
              if (response.data.detail.detail.length == 0) {
                if ($state) $state.complete();
              } else {
                this.products = [
                  ...this.products,
                  ...response.data.detail.detail
                ];
                if ($state) $state.loaded();
              }
            } else {
              this.products = response.data.detail.detail;

              if ($state) $state.loaded();
            }
          }
        });
    },
    addCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
    },
    setShowModalProducts(value) {
      this.filter.search = "";
      this.products = [];
      this.$emit("setShowModalProducts", value);
      if (value) {
        this.products = [];
        this.isLoadingMore = false;
        this.getProducts(false);
      }
    },
    onSelectedProduct(product) {
      product.quantity = 0;
      this.addProductToLists(product);
      this.setShowModalProducts(false);
    },
    scrollMethod($state) {
      setTimeout(async () => {
        if (!this.isLoadingList) {
          this.isLoadingMore = true;
          this.filter.page++;
          await this.getProducts(true, $state);
        }
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.h-80 {
  height: 80px;
}
.wrap-product-panel {
  height: calc(75vh);
}
.product-panel {
  overflow: auto;
  height: calc(75vh - 51px);
}
.product-image {
  height: 80px;
  width: 80px;
  object-fit: contain;
  border: 1px solid #e8e7e7;
  background-color: white;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.btn-search {
  border-radius: 0 50rem 50rem 0 !important;
  background-color: #832abf !important;
  border-color: #832abf;
}

.btn-add-product {
  background-color: #832abf !important;
  border-color: #832abf;
}
.price-active {
  color: #000;
  font-weight: bold;
}
.active-card {
  color: #000;
}

.loading-icon {
  height: 1.8rem !important;
}
</style>
