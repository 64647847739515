var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"รายการสินค้า","hide-footer":""},on:{"show":function($event){return _vm.getProducts(false)},"hide":function($event){return _vm.setShowModalProducts(false)}},model:{value:(_vm.isModalShow),callback:function ($$v) {_vm.isModalShow=$$v},expression:"isModalShow"}},[_c('div',{staticClass:"wrap-product-panel"},[_c('b-input-group',{staticClass:"rounded-pill py-2",scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('button',{staticClass:"btn bg-primary btn-search text-white rounded-pill-right",on:{"click":function($event){return _vm.getProducts(false)}}},[_vm._v(" ค้นหา ")])]},proxy:true}])},[_c('b-form-input',{ref:"keywordInput",staticStyle:{"border-radius":"50px 0 0 50px"},attrs:{"placeholder":"ค้นหาโดยชื่อสินค้า, รหัสบาร์โค้ด"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearchProduct()}},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('div',{staticClass:"product-panel"},[(_vm.products.length >= 0)?_c('div',{staticClass:"mb-5",attrs:{"id":"product-panel"}},[_vm._l((_vm.products),function(item,index){return _c('div',{key:index,staticClass:"my-2"},[_c('div',{staticClass:"p-3 rounded shadow-s bg-color-secondary h-110"},[_c('div',{staticClass:"d-flex"},[(item.imageUrl)?_c('div',{staticClass:"mr-2"},[_c('img',{staticClass:"product-image",attrs:{"src":item.imageUrl}})]):_vm._e(),_c('div',{staticClass:"w-100  d-flex  flex-column",class:{ 'h-80': !item.imageUrl }},[_c('div',{staticClass:"d-flex"},[_c('div',{class:[
                      'line-clamp mr-1',
                      { 'active-card': item.availableQuantity > 0 }
                    ]},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.availableQuantity > 0)?_c('div',{staticClass:"ml-auto"},[_c('b-button',{staticClass:"rounded-pill btn-add-product",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onSelectedProduct(item)}}},[_vm._v(" เพิ่ม ")])],1):_vm._e()]),_c('div',{staticClass:"line-clamp"},[_c('div',[_vm._v(_vm._s(item.barcode))])]),(item.productDiscountType == 0)?_c('div',{staticClass:"d-flex"},[_c('div',{class:[
                      'ml-auto ',
                      { 'price-active': item.availableQuantity > 0 }
                    ]},[(item.promotionPrice !== 0)?_c('del',[_vm._v(_vm._s(_vm._f("numeral")(item.price,"0,0.00")))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"d-flex mt-auto"},[_c('div',{class:[{ 'active-card': item.availableQuantity > 0 }]},[_vm._v(" คงเหลือ "+_vm._s(item.availableQuantity)+" ชิ้น ")]),_c('div',{class:[
                      'ml-auto ',
                      { 'price-active': item.availableQuantity > 0 }
                    ]},[(item.promotionPrice !== 0)?_c('div',[_c('div',[_vm._v(" ราคา "+_vm._s(_vm._f("numeral")(item.promotionPrice,"0,0.00"))+" ")]),_c('div',{staticClass:"text-right"},[_c('del',{staticClass:" text-secondary"},[_vm._v(" "+_vm._s(_vm._f("numeral")(item.price,"0,0.00"))+" ")])])]):_c('div',[_vm._v(" ราคา "+_vm._s(_vm._f("numeral")(item.price,"0,0.00"))+" ")])])])])])])])}),_c('infinite-loading',{ref:"infiniteLoading",attrs:{"spinner":"spiral"},on:{"infinite":_vm.scrollMethod}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[(_vm.isLoadingList && _vm.isLoadingMore)?_c('img',{staticClass:"loading-icon",attrs:{"src":require("@/assets/images/loading.svg"),"alt":"loading"}}):_vm._e()]),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],2):_vm._e(),(_vm.products.length < 1 && !_vm.isLoadingList)?_c('div',{staticClass:"text-color-secondary text-center h-75 d-flex"},[_c('span',{staticClass:"m-auto"},[_vm._v(" ไม่พบสินค้า ")])]):_vm._e(),(_vm.products.length < 1 && _vm.isLoadingList && !_vm.isLoadingMore)?_c('div',{staticClass:"text-color-secondary text-center h-75 w-100 d-flex"},[_c('div',{staticClass:"h-100 w-100 ",attrs:{"slot":"spinner"},slot:"spinner"},[_c('img',{staticClass:"loading-icon my-auto",attrs:{"src":require("@/assets/images/loading.svg"),"alt":"loading"}})])]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }